import { ProgramState } from "../model/program_state";
import { ArithmeticBinaryOperator, ArithmeticExpression, ArithmeticUnaryOperator, DecrementOperator, IncrementOperator, Numeral, Variable } from "../../../model/while+/arithmetic_expression";
class A {
    static eval(expr: ArithmeticExpression, state: ProgramState): number {
        if (expr instanceof ArithmeticBinaryOperator) {
            switch (expr.operator.value) {
                case "+":
                    return A.eval(expr.leftOperand, state) + A.eval(expr.rightOperand, state);
                case "-":
                    return A.eval(expr.leftOperand, state) - A.eval(expr.rightOperand, state);
                case "*":
                    return A.eval(expr.leftOperand, state) * A.eval(expr.rightOperand, state);
                case "%":
                    return A.eval(expr.leftOperand, state) % A.eval(expr.rightOperand, state);
                case "/":
                    if(A.eval(expr.rightOperand, state) === 0) throw Error(`Runtime Error : Division by 0 (${expr.rightOperand.toString()}).`)
                    return Math.floor(A.eval(expr.leftOperand, state) / A.eval(expr.rightOperand, state));
                default:
                    throw Error(`Runtime Error: Illegal arithmetic binary operation (${expr.operator.value})`);
            }
        }

        if (expr instanceof ArithmeticUnaryOperator) {
            switch (expr.operator.value) {
                case "-":
                    return (-1) * A.eval(expr.operand, state);
                default:
                    throw Error(`Runtime Error :Illegal arithmetic unary operation (${expr.operator.value}).`);
            }
        }

        if (expr instanceof Variable) {
            var res = state.get(expr.name);
            if (res) return res;
            else return 0;
        }

        if (expr instanceof Numeral) {
            return expr.value;
        }

        if (expr instanceof IncrementOperator) {
            state.set(expr.variable.name, state.get(expr.variable.name) + 1);
            return state.get(expr.variable.name);
        }
        
        if (expr instanceof DecrementOperator) {
            state.set(expr.variable.name, state.get(expr.variable.name) - 1);
            return state.get(expr.variable.name);
        }

        throw Error(`Runtime Error: arithmetic expression evaluation failed (${expr.toString()}).`);
    }
}

export default A;